body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}
html, body{
  width: 100%;
  height: 100%;
}
.wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  width: 473px;
  background-image: url("./background1.png");
  background-repeat: repeat-y;

}
div.container {
  text-align: center;
}
#mainContainer, #ToS {
  background-color: #fff;
}
#uploadbttn {
  margin-top: 5px;
  margin-bottom: 5px;
}
#uploadbttn > button {
  margin-top: 5px;
  margin-bottom: 5px;
  border: none;
  background: none;
}
#root > div > nav.navbar.navbar-expand-lg{
  background: none!important;
}
#footer {
  background-color: #eaeaea;
}
input[type=text] {
  width: 80%;
  margin-top: 5px;
  margin-bottom: 5px;
}
/*Empty checkbox*/
input#tos {
  background: red;
  margin-top: 5px;
  margin-bottom: 5px;
}

/*Checked checkbox*/
input#tos:checked {
  background: red;
  color: white;
}
#LogoText {
  display:block;
  margin:0 auto;
  text-align: center;
  text-decoration: underline black;
  text-decoration: overline black;
}
#FormWrapper {
  padding-left: 5px;
  padding-right: 5px;
}
#LogoText > a {
  text-decoration: none;
  color: black!important;
  font-size: x-large;
}
#LogoText {
  background-image: url("./components/tbw-logo.png");
  display:block;
  width: 360px;
  height: 48px;
  background-size: cover;
  max-inline-size: 100%;
  block-size: auto;
}
button.menuShow.navbar-toggler {
  margin-left: 45%;
  border-color: red!important;
}
#MainContainer {
  margin-top: 10px;
}
#FileList {
  List-Style: none;
}
#overlay {
  position: relative;
  display: none;
  width: auto;
  height: 653px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
}
.required-field::after {
  content: "*";
  color: red;
}
.error-message {
   color: red;
}


/* Built by www.piotrkarwowski.com */